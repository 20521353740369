@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap);
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html, body, #root {
    min-height: 100%;
}

body {
    background: #f5f5f5;
    -webkit-font-smoothing: antialiased;
}

body, input, button {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.container {
    margin: 80px auto 0;
    max-width: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content{
    width: 100%;
    background: #fff;
    margin-top: 50px;
    border-radius: 4px;
    padding: 30px;
}

.content > p {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 30px;
}

.content form {
    display: flex;
    flex-direction: column;
}

.content form label {
    font-size: 14px;
    color: #444;
    font-weight: bold;
    margin-bottom: 8px;
}

.content form input {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 2px;
    height: 45px;
    padding: 0 15px;
    font-size: 16px;
}

.content button.btn {
    border: 0;
    border-radius: 2px;
    width: 100%;
    height: 42px;
    padding: 0 20px;
    font-size: 16px;
    font-weight: bold;
    background: #e02041;
    color: #fff;
    cursor: pointer;
    transition: -webkit-filter;
    transition: filter;
    transition: filter, -webkit-filter;
}

.content button.btn:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%)
}
.survey-container {
    width: 100%;
    max-width: 1180;
    padding: 0 30px;
    margin: 12px auto;
}

.survey-container header {
    display: flex;
    align-items: center;
}

.survey-container header span {
    font-size: 20px;
    margin-left: 24px;
}

.survey-container header span.logout {
    font-size: 10px;
    margin-left: auto;
    margin-right: 5px;
    padding-left: 5%;
}

.actions {
    display: flex;
}

.back-link {
    font-size: 12px;
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #41414d;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s;
    margin-bottom: 10px;
}

.back-link:hover{
    opacity: 0.8;
}

.survey-container header button {
    height: 35px;
    width: 35px;
    border-radius: 4px;
    border: 1px solid #dcdce6;
    background: transparent;
    margin-left: auto;
    cursor: pointer;
    transition: border-color 0.2s;
    min-height: 35px;
    min-width: 35px;
}

.survey-container header button .power-icon {
    display: flex;
    width: 100%;
}

.survey-container header button:hover {
    border-color: #999;
}

.radioresposta {
    width: 18px;
    height: 18px;
}

.survey-content {
    margin-top: 10px;
}

.survey-content .footer-button{
    display: flex;
    margin-left: auto;
}

.survey-content .footer-button button.btn {
    border: 0;
    border-radius: 2px;
    width: 200;
    height: 42px;
    padding: 0 20px;
    margin-top: 10px;
    margin-left: auto;
    font-size: 16px;
    font-weight: bold;
    background: #e02041;
    color: #fff;
    cursor: pointer;
    transition: -webkit-filter;
    transition: filter;
    transition: filter, -webkit-filter;
}

.survey-content .footer-button button.btn:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%)
}

.content-desc {
    padding-top: 10px;
    width: 100%;
}

.textArea {
    width: 100%;
    min-height: 100px;
    vertical-align: top;
    resize: none;
}

.texto-abertura {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 100px;
    margin-right: 100px;
}

.texto-abertura h3{
    margin-bottom: 10px;
}

.texto-abertura p{
    margin-top: 5px;
}


.cabecario-pesquisa {
    position: -webkit-sticky;
    position: sticky;
}

.selecao-classe{
    padding-left: 30px;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
}

.selecao-classe .radioClasse {
    margin-right: 5px;
    border: solid 1px;
}

.selecao-classe label {
    margin-bottom: 20px;
    vertical-align: sub;
    margin-right: 10px;
}
*{
    font-size: 12px;
}

.survey-container {
    width: 100%;
    max-width: 1180;
    padding: 0 30px;
    margin: 12px auto;
}

.survey-container header {
    display: flex;
    align-items: center;
}

.survey-container header span {
    font-size: 20px;
    margin-left: 24px;
}

.survey-container header span.logout {
    font-size: 10px;
    margin-left: auto;
    margin-right: 5px;
    padding-left: 5%;
}

.actions {
    display: flex;
}

.back-link {
    font-size: 12px;
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #41414d;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s;
    margin-bottom: 10px;
}

.back-link:hover{
    opacity: 0.8;
}

.survey-container header button {
    height: 35px;
    width: 35px;
    border-radius: 4px;
    border: 1px solid #dcdce6;
    background: transparent;
    margin-left: auto;
    cursor: pointer;
    transition: border-color 0.2s;
    min-height: 35px;
    min-width: 35px;
}

.survey-container header button .power-icon {
    display: flex;
    width: 100%;
}

.survey-container header button:hover {
    border-color: #999;
}


.survey-content {
    margin-top: 10px;
}

.survey-content .footer-button{
    display: flex;
    margin-left: auto;
}

.survey-content .footer-button button.btn {
    border: 0;
    border-radius: 2px;
    width: 200;
    height: 42px;
    padding: 0 20px;
    margin-top: 10px;
    margin-left: auto;
    font-size: 16px;
    font-weight: bold;
    background: #e02041;
    color: #fff;
    cursor: pointer;
    transition: -webkit-filter;
    transition: filter;
    transition: filter, -webkit-filter;
}

.survey-content .footer-button button.btn:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%)
}

.content-desc {
    padding-top: 10px;
    width: 100%;
}

.textArea {
    width: 100%;
    min-height: 100px;
    vertical-align: top;
    resize: none;
}

.texto-abertura {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 100px;
    margin-right: 100px;
}

.texto-abertura h3{
    margin-bottom: 10px;
}

.texto-abertura p{
    margin-top: 5px;
}


.cabecario-pesquisa {
    position: -webkit-sticky;
    position: sticky;
}

.selecao-classe{
    padding-left: 30px;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
}

.selecao-classe .radioClasse {
    margin-right: 5px;
    border: solid 1px;
}

.selecao-classe label {
    margin-bottom: 20px;
    vertical-align: sub;
    margin-right: 10px;
}
.parameter-container {

    padding: 0 30px;
    margin: 32px auto;
}

.parameter-container header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.parameter-container header span {
    font-size: 20px;
    margin-left: 24px;
}

.parameter-container header span.logout {
    font-size: 10px;
    margin-left: auto;
    margin-right: 5px;
}

.parameter-container header button {
    height: 35px;
    width: 35px;
    border-radius: 4px;
    border: 1px solid #dcdce6;
    background: transparent;
    margin-left: auto;
    cursor: pointer;
    transition: border-color 0.2s
}

.parameter-container header button .power-icon {
    display: flex;
    width: 100%;
}

.parameter-container header button:hover {
    border-color: #999;
}


.parameter-content{
    background: #fff;
    padding-bottom: 20px;
    display: flex;
    padding-right: 30px;
}

.parameter-content p{
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 18px;
    
}

.actions {
    display: flex;
}

.back-link {
    font-size: 12px;
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #41414d;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s;
    margin-bottom: 10px;
}

.back-link:hover{
    opacity: 0.8;
}

.parameter-table {
    font-size: 12px;
    display: flex;
    padding: 5px;
}

.parameter-table form {
    display: flex;
    flex-direction: row;
    min-height: 400px;
    flex-wrap: wrap;
}

.parameter-table table {
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    padding: 10px;
}

.parameter-table table thead {
    font-size: 15px;
    margin-bottom: 30px;
    text-align: left;
}

.parameter-table table thead th {
    height: 40px;
}

.parameter-table table thead th.regiao-classe {
    height: 40px;
    padding-left: 20px;
}

.parameter-table table tbody {
    height: 20px;
    padding-bottom: 10px;
}

.parameter-table table tbody tr {
    height: 20px;
}


.parameter-table table tbody tr td.name-classe{
    width: 200px;
    padding-left: 20px;
}

.parameter-content .footer-button{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 20px;
    min-width: 100px;
}

.parameter-content .footer-button button.btn {
    border: 0;
    border-radius: 2px;
    height: 42px;
    padding: 0 20px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    background: #48c500;
    color: #fff;
    cursor: pointer;
    transition: -webkit-filter;
    transition: filter;
    transition: filter, -webkit-filter;
}

.parameter-content .footer-button button.btn:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%)
}

.parameter-content .footer-button button.btn-cancelar {
    border: 0;
    border-radius: 2px;
    height: 42px;
    padding: 0 20px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    background: #e02041;
    color: #fff;
    cursor: pointer;
    transition: -webkit-filter;
    transition: filter;
    transition: filter, -webkit-filter;
}

.parameter-content .footer-button button.btn-cancelar:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%)
}

input {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 2px;
    height: 35px;
    padding: 0 15px;
    font-size: 16px;
}
.resp-survey-container {
    width: 100%;
    max-width: 1180;
    padding: 0 30px;
    margin: 32px auto;
}

.resp-survey-container header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.resp-survey-container header span {
    font-size: 20px;
    margin-left: 24px;
}

.resp-survey-container header span.logout {
    font-size: 10px;
    margin-left: auto;
    margin-right: 5px;
}

.resp-survey-container header button {
    height: 35px;
    width: 35px;
    border-radius: 4px;
    border: 1px solid #dcdce6;
    background: transparent;
    margin-left: auto;
    cursor: pointer;
    transition: border-color 0.2s
}

.resp-survey-container header button .power-icon {
    display: flex;
    width: 100%;
}

.resp-survey-container header button:hover {
    border-color: #999;
}


.resp-survey-content{
    width: 100%;
    max-width: 900px;
    padding: 0 30px;
    margin: 32px auto;
    background: #fff;
    min-height: 300px;
    padding-bottom: 20px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px 2px gray;
}

.resp-survey-content p{
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 18px;
}

.actions {
    display: flex;
}

.back-link {
    font-size: 12px;
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #41414d;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s;
    margin-bottom: 10px;
}

.back-link:hover{
    opacity: 0.8;
}
.survey-container {
    width: 100%;
    max-width: 1180;
    padding: 0 30px;
    margin: 12px auto;
}

.survey-container header {
    display: flex;
    align-items: center;
}

.survey-container header span {
    font-size: 20px;
    margin-left: 24px;
}

.survey-container header span.logout {
    font-size: 10px;
    margin-left: auto;
    margin-right: 5px;
    padding-left: 5%;
}


.survey-container header button {
    height: 35px;
    width: 35px;
    border-radius: 4px;
    border: 1px solid #dcdce6;
    background: transparent;
    margin-left: auto;
    cursor: pointer;
    transition: border-color 0.2s;
    min-height: 35px;
    min-width: 35px;
}

.survey-container header button .power-icon {
    display: flex;
    width: 100%;
}

.survey-container header button:hover {
    border-color: #999;
}

.PessoaAvulsa {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    text-align: center;
}

.PessoaAvulsa label{
    padding-top: 5px;
}

.PessoaAvulsa .nomePessoa {
    margin-left: 10px;
    width: 300px;
    height: 30px;
    color: #333;
    border: 1px solid #dcdce6;
    border-radius: 6px;
}

.radioresposta {
    width: 18px;
    height: 18px;
}

.survey-content .footer-button{
    display: flex;
    margin-left: auto;
}

.survey-content .footer-button button.btn {
    border: 0;
    border-radius: 2px;
    width: 200;
    height: 42px;
    padding: 0 20px;
    margin-top: 10px;
    margin-left: auto;
    font-size: 16px;
    font-weight: bold;
    background: #e02041;
    color: #fff;
    cursor: pointer;
    transition: -webkit-filter;
    transition: filter;
    transition: filter, -webkit-filter;
}

.survey-content .footer-button button.btn:hover {
    -webkit-filter: brightness(90%);
            filter: brightness(90%)
}

.content-desc {
    padding-top: 10px;
    width: 100%;
}

.textArea {
    width: 100%;
    min-height: 100px;
    vertical-align: top;
    resize: none;
}
.dashboard-container {
    width: 100%;
    max-width: 1180;
    padding: 0 30px;
    margin: 32px auto;
    min-width: 700px;
}

.dashboard-container header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.dashboard-container header span {
    font-size: 20px;
    margin-left: 24px;
}

.dashboard-container header span.logout {
    font-size: 10px;
    margin-left: auto;
    margin-right: 5px;
}

.dashboard-container header button {
    height: 35px;
    width: 35px;
    border-radius: 4px;
    border: 1px solid #dcdce6;
    background: transparent;
    margin-left: auto;
    cursor: pointer;
    transition: border-color 0.2s
}

.dashboard-container header button .power-icon {
    display: flex;
    width: 100%;
}

.dashboard-container header button:hover {
    border-color: #999;
}

.dashboard-header {
    width: 100%;
    max-width: 1180;
    background: #fff;
    margin-top: 20px;
    padding: 20px;
    display: flex;
    flex-direction: row;
}

.dashboard-header .titulo {
    font-size: 16px;
    font-weight: 600;
}

.dashboard-header .Contador {
    margin-left: auto;
    padding-right: 30px;
}

.dashboard-header .Contador span {
    color: red;
    font-weight: 600;
}

.dashboard-content {
    width: 100%;
    max-width: 1180;
    background: #fff;
    min-height: 300px;
    padding-bottom: 20px;
    display: flex;
    padding-right: 30px;
}

.dashboard-content .tableDashboard {
    padding: 10px;
    width: 60%;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0 18px;
    
}

.dashboard-content .tableDashboard thead {
    font-size: 16px;
    padding-bottom: 10px;
    font-weight: bold;
}

.dashboard-content .tableDashboard tbody td {
    border-bottom: 1px solid;
}

.dashboard-content .tableDashboard tfoot {
    font-size: 15px;
    font-weight: bold;
}

.dashboard-content .tableDashboard tfoot .footer-cell1 {
    text-align: right;
    padding-right: 10px;
}

.dashboard-content .tableAction {
    padding: 10px;
    width: 40%;
    text-align: center;
    border-collapse: separate;
    border-spacing: 0 18px;
}

.menu-actions{
    width: 200px;
}

.link-actions {
    font-size: 12px;
    display: flex;
    color: #000000;
    text-decoration: none;
    font-weight: bold;
    transition: opacity 0.2s;
    margin-bottom: 10px;
    margin-left: 20px;
}

.link-actions:hover{
    opacity: 0.8;
}

/* Card */

.header-card-view {
    display: flex;
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
}


.card {
    font-size: 15px;
  }


.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 100px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #eeeeee;
    border-radius: 0.25rem;
    margin-top: 30px;
    margin-left: 5px;
  }
  
  .card>hr {
    margin-right: 0;
    margin-left: 0;
  }

  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    
  }
  
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
  
  .card-header+.list-group .list-group-item:first-child {
    border-top: 0;
  }
  
  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border-top: 1px solid #eeeeee;
  }

  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  }

  .card .card-header {
    border-bottom: none;
    background: transparent;
  }

  .card .card-header .title {
    color: #fff;
  }
  
  .card .card-header .nav-tabs {
    padding: 0;
  }
  
  .card .card-header.card-header-image {
    position: relative;
    padding: 0;
    z-index: 1;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
    border-radius: 6px;
  }
  
  .card .card-header.card-header-image img {
    width: 100%;
    border-radius: 6px;
    pointer-events: none;
    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  }
  
  .card .card-header.card-header-image.no-shadow {
    box-shadow: none;
  }
  
  .card .card-header.card-header-image.no-shadow.shadow-normal {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  }
  
  .card .card-header.card-header-image.no-shadow .colored-shadow {
    display: none !important;
  }

.card .card-header-warning .card-icon {
  background: linear-gradient(60deg, #ffa726, #fb8c00);
}

.card [class*="card-header-"],
.card[class*="bg-"] {
  color: #fff;
}

.card .card-stats {
  background: transparent;
  display: flex;
}


.pendente {
    color: rgb(255, 0, 0);
    opacity: 70%;
}

.concluido {
    color: rgb(0, 179, 9);
    opacity: 70%;
    font-weight: 500;
}

.card .card-stats .author,
.card .card-stats .stats {
  display: inline-flex;
}

.card {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.card .table tr:first-child td {
  border-top: none;
}

.card .card-title {
  margin-top: 0;
  margin-bottom: 3px;
}

.card .card-body {
  padding: 0.9375rem 20px;
  position: relative;
}

.card .card-body .form-group {
  margin: 8px 0 0;
}

.card .card-header {
  z-index: 3 !important;
}

.card .card-header .card-title {
  margin-bottom: 10px;
  margin-left: auto;
  text-align: right;
}

.card .card-header .card-category {
  margin: 0;
}

.card .card-header.card-header-text {
  display: inline-block;
}

.card .card-header.card-header-text:after {
  content: "";
  display: table;
}

.card .card-header.card-header-icon i {
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 33px;
}

.card .card-header.card-header-icon .card-title {
  margin-top: 50px;
  color: #3C4858;
}

.card .card-header.card-header-icon h4,
.card .card-header.card-header-text h4 {
  font-weight: 300;
}


.card .card-body+.card-footer,
.card .card-footer {
  padding: 0;
  padding-top: 10px;
  margin: 0 15px 10px;
  border-radius: 0;
  justify-content: space-between;
  align-items: center;
}

.card .card-body+.card-footer .stats,
.card .card-footer .stats {
  color: #999999;
  font-size: 12px;
  line-height: 22px;
}


.card [class*="card-header-"] {
  margin: 0px 15px 0;
  padding: 0;
  position: relative;
}

.card [class*="card-header-"] .card-icon {
  border-radius: 3px;
  background-color: #999999;
  padding: 15px;
  margin-top: -20px;
  margin-right: 15px;
  float: left;
  min-width: 100px;
}

/* Card Menu */
.menu-actions {
    display: flex;
    width: 500px;
}

.card-menu {
    font-size: 20px;
    font-weight: 500;
  }


.card-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 100px;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid #eeeeee;
    border-radius: 0.25rem;
    margin-top: 30px;
    margin-left: 10px;
    width: 90%;
  }
  
  .card-menu>hr {
    margin-right: 0;
    margin-left: 0;
  }

  .card-menu-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .card-menu-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
  
  .card-menu-header+.list-group .list-group-item:first-child {
    border-top: 0;
  }
  
  .card-menu-footer {
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border-top: 1px solid #eeeeee;
  }

  .card-menu-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  }

  .card-menu .card-menu-header {
    border-bottom: none;
    background: transparent;
  }

  .card-menu .card-menu-header .title {
    color: #fff;
  }
  
  .card-menu .card-menu-header .nav-tabs {
    padding: 0;
  }
  
  .card-menu .card-menu-header.card-menu-header-image {
    position: relative;
    padding: 0;
    z-index: 1;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
    border-radius: 6px;
  }
  
  .card-menu .card-menu-header.card-menu-header-image img {
    width: 100%;
    border-radius: 6px;
    pointer-events: none;
    box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  }
  
  .card-menu .card-menu-header.card-menu-header-image.no-shadow {
    box-shadow: none;
  }
  
  .card-menu .card-menu-header.card-menu-header-image.no-shadow.shadow-normal {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  }
  
  .card-menu .card-menu-header.card-menu-header-image.no-shadow .colored-shadow {
    display: none !important;
  }

.card-menu .card-menu-header-warning .card-menu-icon {
    background: linear-gradient(60deg, #ff0000af, #940000);
}

.card-menu [class*="card-header-"],
.card-menu[class*="bg-"] {
  color: #fff;
}

.card-menu .card-menu-stats {
  background: transparent;
  display: flex;
}

.card-menu .card-menu-stats .author,
.card-menu .card-menu-stats .stats {
  display: inline-flex;
}

.card-menu {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.card-menu .table tr:first-child td {
  border-top: none;
}

.card-menu .card-menu-title {
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 15px;
  padding-left: 10px;
}

.card-menu .card-menu-title {
    border-bottom: 1px solid #940000;
}

.card-menu .card-menu-body {
  padding: 0.9375rem 20px;
  position: relative;
}

.card-menu .card-menu-body .form-group {
  margin: 8px 0 0;
}

.card-menu .card-menu-header {
  z-index: 3 !important;
}

.card-menu .card-menu-header .card-menu-title {
  margin-bottom: 10px;
}

.card-menu .card-menu-header .card-menu-category {
  margin: 0;
}

.card-menu .card-menu-header.card-menu-header-text {
  display: inline-block;
}

.card-menu .card-menu-header.card-menu-header-text:after {
  content: "";
  display: table;
}

.card-menu .card-menu-header.card-menu-header-icon i {
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 33px;
}

.card-menu .card-menu-header.card-menu-header-icon .card-menu-title {
  margin-top: 50px;
  color: #3C4858;
}

.card-menu .card-menu-header.card-menu-header-icon h4,
.card-menu .card-menu-header.card-menu-header-text h4 {
  font-weight: 300;
}


.card-menu .card-menu-body+.card-menu-footer,
.card-menu .card-menu-footer {
  padding: 0;
  padding-top: 10px;
  margin: 0 15px 10px;
  border-radius: 0;
  justify-content: space-between;
  align-items: center;
}

.card-menu .card-menu-body+.card-menu-footer .stats,
.card-menu .card-menu-footer .stats {
  color: #999999;
  font-size: 12px;
  line-height: 22px;
}


.card-menu [class*="card-menu-header-"] {
  margin: 0px 15px 0;
  padding: 0;
  position: relative;
}

.card-menu [class*="card-menu-header-"] .card-menu-icon {
  border-radius: 3px;
  background-color: #999999;
  padding: 15px;
  margin-top: -20px;
  margin-right: 15px;
  float: left;
  min-width: 150px;
}










